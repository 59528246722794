import dayjs from "dayjs";
import 'dayjs/locale/el';
import 'dayjs/locale/en';

export abstract class TimeUtil {
  public static formatDateTime (date: Date): string {
    return dayjs(date).locale(localStorage.getItem('lang') || 'el').format('MMM DD, YYYY HH:mm')
  }

  public static formatDate (date: Date): string {
    return dayjs(date).locale(localStorage.getItem('lang') || 'el').format('MMM DD, YYYY')
  }

  public static formatDateOnly (date: Date): string {
    if (date) {
      return dayjs(date).locale(localStorage.getItem('lang') || 'el').format('YYYY-MM-DD') 
    } else {
      return ""
    }
  }

  public static formatTimeOnly (date: Date): string {
    if (date) {
      return dayjs(date).format('HH:mm') 
    } else {
      return ""
    }
  }

  public static formatUnixTS (unixTs: number): string {
    return dayjs.unix(unixTs).locale(localStorage.getItem('lang') || 'el').format('MMM DD, YYYY HH:mm')
  }
}
