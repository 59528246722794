
  import { Component, Vue } from 'vue-property-decorator'

  import QuickAddCalendarModal from './Modals/Appointments/QuickAddCalendarModal.vue'

  import { commonModule } from '@/store/modules/store-accessor'
  
  @Component({
    components: {
      QuickAddCalendarModal,
    }
  })
  export default class TheThirdModal extends Vue {
    get modalName () {
      return commonModule.thirdModal.name
    }
  
    get modalPayload () {
      return commonModule.thirdModal.payload
    }
  };
  