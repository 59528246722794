
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { tasksModule } from "@/store/modules/store-accessor";

@Component
export default class SnoozeTask extends Vue {
  @Prop() task: Record<string, any>;

  isSnoozing = false;
  snoozeOptions = [1, 2, 3, 7];

  showSnooze() {
    return this.task.due_at && [2].includes(this.task.task_status_id);
  }

  async snoozeTask(days: number) {
    this.isSnoozing = true;
    await tasksModule.snoozeTask({ task: this.task, days });
    this.isSnoozing = false;
  }
};
