
import { Component, Vue } from 'vue-property-decorator'
import { commonModule, companyModule, actionTypesModule, itemTypesModule } from '@/store/modules/store-accessor'
import Dropdown from '../components/dropdown/Dropdown.vue'
import DropdownItem from '../components/dropdown/DropdownItem.vue'
import FilterDropdown from '../components/dropdown/filter/FilterDropdown.vue'
import { customSearch, periodOptionToLabel, timeChargedUnitOption } from "@/helpers/data";

@Component({
  components: { Dropdown, DropdownItem, FilterDropdown }
})
export default class Management extends Vue {
  typesTableheaders: any [] = [
    { text: this.$t("actionTypeText"), sortable: true, class: 'row-style', value: 'text' },
    { text: this.$t("color"), sortable: true, class: 'row-style', value: 'color' },
    { text: this.$t("cost"), sortable: true, class: 'row-style', value: 'cost' },
    { text: this.$t("period"), sortable: true, class: 'row-style', value: 'periodic_option' },
    { text: this.$t("active"), sortable: true, class: 'row-style', value: 'is_archived' },
    { text: '', align: 'right', width: '0px', sortable: false, class: 'row-style', value: 'id' }
  ]

  itemsTableheaders: any [] = [
    { text: this.$t("itemTypeText"), sortable: true, class: 'row-style', value: 'text' },
    { text: this.$t("active"), sortable: true, class: 'row-style', value: 'is_archived' },
    { text: '', align: 'right', sortable: false, class: 'row-style', value: 'id' }
  ]

  model: any = {
    typesSearch: '',
    itemsSearch: '',
    selected: [] as string[]
  }

  get personnelLength() {
    return companyModule.personnel.length
  }

  get actionsSearchLabel() {
    return this.$t("actionTypesSearch")
  }

  get itemsSearchLabel() {
    return this.$t("itemTypesSearch")
  }

  get actionTypes(){
    return actionTypesModule.actionTypes;
  }

  get itemTypes(){
    return itemTypesModule.itemTypes;
  }

  get isLoadingTableActionTypes() {
    return actionTypesModule.isLoadingTable;
  }

  get isLoadingTableItemTypes() {
    return itemTypesModule.isLoadingTable;
  }

  get companyInfo() {
    return companyModule.company;
  }

  get CompanyPreferences() {
    return Object.entries(companyModule.company?.preferences).map(([key, value]) => ({key, value }));
  }

  get JobPreferences() {
    const generalOptions = ['job_description', 'job_export_cost', 'job_export_show_actual_duration' ];
    const optionsArray = this.CompanyPreferences.filter(obj => generalOptions.includes(obj.key));
    return optionsArray;
  }

  get TechnicianPreferences() {
    const generalOptions = ['technician_view_client_phone', 'technician_view_job_costs', 'technician_view_job_costs'];
    const optionsArray = this.CompanyPreferences.filter(obj => generalOptions.includes(obj.key));
    return optionsArray;
  }

  get InstallationPreferences() {
    const generalOptions = ['installation_description', 'job_export_show_installation_description'];
    const optionsArray = this.CompanyPreferences.filter(obj => generalOptions.includes(obj.key));
    return optionsArray;
  }

  setToArchived(action: any) {
    commonModule.showModal({ name: 'toggle-archive-action-type-modal', payload: { actionType: action } })
  }

  setToArchivedItemType(item: any) {
    commonModule.showModal({ name: 'toggle-archive-item-type-modal', payload: { itemType: item } })
  }

  customSearchFilter(value: any, search: any, item: any) {
    return customSearch(value, search, item);
  }

  periodicLabel(option: string) {
     return periodOptionToLabel(option);
  }

  async mounted () {
    commonModule.initSnackbar({});
    await actionTypesModule.getAllActionTypes();
    await itemTypesModule.getItemTypes();
    await companyModule.getCompanyInfo();
    actionTypesModule.setIsLoadingTable(false);
    itemTypesModule.setIsLoadingTable(false);
  }

  // --- Action Types ---
  async addActionType() {
    commonModule.showModal({ name: 'add-action-type-modal' })
  }

  async editActionType(actionType: any) {
    await actionTypesModule.getActionTypeById(actionType.id);
    commonModule.showModal({ name: 'edit-action-type-modal', payload: { actionType: actionType } })
  }

  async deleteActionType(actionType: any) {
    if (!actionType.is_used) {
      commonModule.showModal({ name: 'delete-action-type-modal', payload: { actionType: actionType } })
    }
  }

  actionCostText(action: Record<string, any>) {
    if(action.is_time_charged) {
      return `/ ${timeChargedUnitOption(action.unit)?.text}`;
    }
  }
  // --- Action Types End ---

  // --- Item Types ---
    async addItemType() {
    commonModule.showModal({ name: 'add-item-type-modal' })
  }

  async editItemType(itemType: any) {
    await actionTypesModule.getActionTypeById(itemType.id);
    commonModule.showModal({ name: 'edit-item-type-modal', payload: { itemType: itemType } })
  }

  async deleteItemType(itemType: any) {
    if (!itemType.is_used) {
      commonModule.showModal({ name: 'delete-item-type-modal', payload: { itemType: itemType } })
    }
  }
  // --- Item Types End ---

  async toggleInstallationDescription(key: any) {
    commonModule.showModal({ name: 'toggle-company-preferences-modal', payload: { key: key } })
  }

}
