import { VuexModule, Module, Action, Mutation } from "vuex-module-decorators";
import ExpensesApi from "@/api/expenses-api";
import { installationsModule, partnersModule, paymentsModule } from '@/store/modules/store-accessor'
import dayjs from "dayjs";

@Module({ namespaced: true, name: "expenses" })
export default class ExpensesModule extends VuexModule {

  public currentExpense: ExpenseDto = {} as ExpenseDto;

  @Action
  async deleteExpense(data: Record<string, any>) {
    const expensesApi = new ExpensesApi()
    await expensesApi.deleteExpense(data.id)
    if(data.installation_id) {
      await installationsModule.getInstallationInfo(data.installation_id);
    } else {
      await paymentsModule.getPaymentsAction(paymentsModule.filters);
      await paymentsModule.getPaymentsTotals(paymentsModule.filters);
    }
  }

  @Action
  async deleteExpenseFile(data: Record<string, any>) {
    const expensesApi = new ExpensesApi()
    await expensesApi.deleteExpenseFile(data.id)
    if(data.installation_id) {
      await installationsModule.getInstallationInfo(data.installation_id);
    }
  }

  @Action
  async editExpense(expense: Record<string, any>) {
    const expensesApi = new ExpensesApi()
    if(expense.created_at_date && expense.created_at_time) {
      expense.created_at = dayjs(expense.created_at_date + ' ' +  expense.created_at_time);
      delete expense.created_at_date;
      delete expense.created_at_time;
    }

    await expensesApi.updateExpense(expense.id, { cost: expense.cost, notes: expense.notes, payment_type_id: expense.payment_type.id,
      assignee_id: expense.assignee_id.id, created_at: expense.created_at })

      if(expense.installation_id) {
        await installationsModule.getInstallationInfo(expense.installation_id);
      } else {
        await paymentsModule.getPaymentsAction(paymentsModule.filters);
        await paymentsModule.getPaymentsTotals(paymentsModule.filters);
      }

    if(expense.partner_id) {
      await partnersModule.getPartnersInfo(expense.partner_id);
    }
  }

  @Action
  async addExpenseFile({expenseId, file} : {expenseId: string, file: any}) {
    const expensesApi = new ExpensesApi()
    const response = await expensesApi.createInstallationFile(expenseId, file)
    return response;
  }

  @Action
  async getExpensionFile(expenseId: string) {
      const expensesApi = new ExpensesApi();
      const response = await expensesApi.getExpenseFile(expenseId);
      const blob = new Blob([response], { type: "application/file" });
      return blob
  }

  @Action
  async addeExpense(data: Record<string, any>) {
    data.assignee_id = data.assignee_id.id;
    data.payment_type_id = data.payment_type.id

    if(data.created_at_date && data.created_at_time) {
      data.created_at = dayjs(data.created_at_date + ' ' +  data.created_at_time);
    }

    const expensesApi = new ExpensesApi()
    const respData = await expensesApi.createExpense(data);
    return respData;
  }

  @Action({ commit: "setCurrentExpense" })
  async getExpenseDetails(currentExpenseId: string): Promise<ExpenseDto> {

    const expensesApi = new ExpensesApi();
    const expenseDetails = await expensesApi.getExpenseDetails(currentExpenseId);
    return expenseDetails;
  }

  @Mutation
  public setCurrentExpense(currentExpense: ExpenseDto): void {
    this.currentExpense = currentExpense;
  }

  @Mutation
  public unsetCurrentExpense(): void {
    this.currentExpense = {} as ExpenseDto;
  }

}
