
import {
  appointmentsModule,
  installationsModule,
  commonModule,
  statsModule,
  dashboardModule,
} from "@/store/modules/store-accessor";
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import {
  calcDuration,
  appointmentStatusToColor,
  appointmentStatusToName,
  formatDateWithoutSeconds,
  getOptimalTextColor,
  mergedAddressAndArea,
} from "../../helpers/data";

@Component
export default class DashboardTodaysAppointmentsTable extends Vue {
  @Prop() readonly headers: any;
  @Prop() readonly items: any;
  @Prop() readonly search: string;
  @Prop() readonly isLoading: boolean;

  setStatusColor(status: string) {
    return appointmentStatusToColor(status);
  }

  setStatusText(status: string) {
    return appointmentStatusToName(status);
  }

  setDuration(start: Date, end: Date) {
    return calcDuration(start, end);
  }

  setScheduledStart(scheduled_start: Date) {
    return formatDateWithoutSeconds(scheduled_start);
  }

  optimalTextColor(color: string) {
    return getOptimalTextColor(color);
  }

  setMergedAddressAndArea(address: string, area: string) {
    return mergedAddressAndArea(address, area);
  }

  async openInstallationSideView(appointment: any) {
    try {
      dashboardModule.setIsLoadingAppointmentsList(true);
      await installationsModule.getInstallationInfo(
        appointment.installation_id
      );
      dashboardModule.setIsLoadingAppointmentsList(false);
      commonModule.showSideview({
        name: "installations-sideview",
        payload: {
          installationId: appointment.installation_id,
          tab: 2,
          appointmentId: appointment.id,
        },
      });
    } catch (error) {
      dashboardModule.setIsLoadingAppointmentsList(false);
    }
  }
}
