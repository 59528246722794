
import { Component, Vue, Prop, PropSync } from 'vue-property-decorator'
import { mapTileProviders } from "@/helpers/data";
import { LMap, LTileLayer, LMarker, LControlLayers, LControl } from 'vue2-leaflet';
import { Icon } from "leaflet";
import { getGoogleMapsInfo } from "@/helpers/data";

Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

@Component({
  components: {
    LMap, LTileLayer, LMarker, LControlLayers, LControl
  }
})
export default class MapAddressCoordinates extends Vue {
  @PropSync('markerCoords', { default: [37.983810, 23.727539] }) mapCoords!: any[];
  @PropSync('lat', { default: 37.983810 }) mapLat!: any;
  @PropSync('long', { default: 23.727539 }) mapLong!: any;
  @Prop({ default: 16 }) zoom!: any;

  async getCoordinates(event: Record<string, any>) {
    this.mapCoords = [event.target?._latlng?.lat, event.target?._latlng?.lng];
    this.mapLong = event.target?._latlng?.lng;
    this.mapLat = event.target?._latlng?.lat;
    let addressInfo = await getGoogleMapsInfo(event.target?._latlng?.lat, event.target?._latlng?.lng);

    this.$emit('update', addressInfo);
  }

  mapTileProviders() {
    return mapTileProviders();
  }

  latitudeRules = [
  (val: any) => {
    if (!val) return true; // Allow empty value
    const latitudeRegex = /^-?(90(\.0+)?|[1-8]?\d(\.\d+)?)$/;
    return latitudeRegex.test(val) || this.$i18n.t("latitudeRule");
  }
];

  longitudeRules = [
    (val: any) => {
      if (!val) return true; // Allow empty value
      const longitudeRegex = /^-?(180(\.0+)?|1[0-7]\d(\.\d+)?|[1-9]?\d(\.\d+)?)$/;
      return longitudeRegex.test(val) || this.$i18n.t("longitudeRule");
    }
];

  async updateCoordinates() {
    if(parseFloat((this.mapLat as any)) && parseFloat((this.mapLong as any))) {
      this.mapCoords = [parseFloat((this.mapLat as any)), parseFloat((this.mapLong as any))];
      let addressInfo = await getGoogleMapsInfo(this.mapLat, this.mapLong);
      this.$emit('update', addressInfo);
    }
  }
}
