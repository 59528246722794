
import { Component, Vue, Prop } from "vue-property-decorator";
import {
  commonModule,
  actionTypesModule,
  expensesModule,
  partnersModule,
  installationsModule,
  companyModule,
  itemsModule,
  jobsModule,
} from "@/store/modules/store-accessor";
import { TimeUtil } from "@/helpers/TimeUtil";
import Dropdown from "../../../components/dropdown/Dropdown.vue";
import DropdownItem from "../../../components/dropdown/DropdownItem.vue";
import {
  formatAmount,
  getOptimalTextColor,
  entryIcon,
  sortTableDates,
  mergedAddressAndArea,
} from "@/helpers/data";
import FileSaver from "file-saver";
import dayjs from "dayjs";

@Component({
  components: { Dropdown, DropdownItem },
})
export default class PartnersSideviewJobsTab extends Vue {
  installation: Record<string, any>;

  isDownloadingCompleteJobPdf = false;

  mounted() {
    commonModule.getPaymentTypeCombo();
    commonModule.getActionCombo();
    commonModule.getDurationCombo();
    actionTypesModule.getActionTypesCombo();
    commonModule.getPersonnelPartnersCombo();
  }

  tableheaders: any[] = [
    {
      class: "font-weight-black",
      text: this.$t("name"),
      sortable: false,
      value: "client_name",
    },
    {
      class: "font-weight-black",
      text: this.$t("address"),
      sortable: false,
      value: "address",
    },
    {
      class: "font-weight-black",
      text: this.$t("actions"),
      sortable: false,
      value: "actions",
    },
    {
      class: "font-weight-black",
      text: this.$t("item"),
      sortable: false,
      value: "items",
    },
    {
      class: "font-weight-black",
      text: this.$t("last_job_entry"),
      sortable: true,
      value: "last_job_entry_at",
      sort: (a: string, b: string) => sortTableDates(a, b),
      width: "250px",
    },
    {
      class: "font-weight-black",
      text: this.$t("cost"),
      sortable: true,
      value: "cost",
      width: "100px",
      align: "center",
    },
    {
      class: "font-weight-black",
      text: this.$t("balance"),
      sortable: true,
      value: "balance",
      width: "105px",
      align: "center",
    },
    {
      class: "",
      text: "",
      value: "is_complete",
      width: "85px",
      sortable: false,
      align: "right",
    }, // Move expand arrow to the right
    { class: "", text: "", value: "data-table-expand" }, // Move expand arrow to the right
  ];

  calcProfit(job: Record<string, any>) {
    const actions = job
      ?.filter((item: any) => item.entry_type == "action")
      .reduce((accumulator: number, item: any) => {
        return accumulator + parseFloat(item.cost);
      }, 0);
    const expenses = job
      ?.filter((item: any) => item.entry_type == "expense")
      .reduce((accumulator: number, item: any) => {
        return accumulator + parseFloat(item.cost);
      }, 0);
    const materials = job
      ?.filter((item: any) => item.entry_type == "material")
      .reduce((accumulator: number, item: any) => {
        return accumulator + parseFloat(item.cost);
      }, 0);
    return actions + materials - expenses;
  }

  formatDate(date: Date): string {
    return TimeUtil.formatDateTime(date);
  } 

  get assigneeLabel() {
    return this.$i18n.t("partner");
  }

  
    costTextColor(action: any) {
      switch (action.entry_type) {
        case 'payment':
          return 'darkGreen'
        case 'expense':
          return 'expenseChipColor'
        case 'action':
          return 'primary'
        case 'material':
          return 'primary'
        default:
          return '';
      }
    }
  
  get jobs() {
    return partnersModule.mapJobsAndActions;
  }

  calcCost(job: any, entryType: string) {
    if (entryType === "payment") {
      return job
        ?.filter((item: any) => item.entry_type == entryType)
        .reduce((accumulator: number, item: any) => {
          return accumulator + parseFloat(item.amount);
        }, 0);
    } else {
      return job
        ?.filter((item: any) => item.entry_type == entryType)
        .reduce((accumulator: number, item: any) => {
          return accumulator + parseFloat(item.cost);
        }, 0);
    }
  }

  costColor(action: any) {
    switch (action.entry_type) {
      case "payment":
        return "darkGreen";
      case "expense":
        return "expenseChipColor";
      case "action":
        return "primary";
      case "material":
        return "primary";
      default:
        return "";
    }
  }

  getEntryIcon(action: any) {
    return entryIcon(action);
  }

  entryColor(action: any) {
    switch (action.entry_type) {
      case "action":
        return action.type_color;
      case "payment":
        return "darkGreen";
      case "material":
        return "materialChipColor";
      case "expense":
        return "expenseChipColor";
      default:
        return "orange";
    }
  }

  entryTextColor(action: any) {
    switch (action.entry_type) {
      case "action":
        return getOptimalTextColor(action.type_color);
      case "payment":
        return "darkGreen";
      case "expense":
        return "expenseChipColor";
      default:
        return "black";
    }
  }

  entryName(action: any) {
    switch (action.entry_type) {
      case "action":
        return `${action.type_name} ${action.unitInfo}`;
      case "payment":
        return this.$i18n.t(action.entry_type);
      case "material":
        return action.description || this.$i18n.t(action.entry_type);
      case "expense":
        return this.$i18n.t(action.entry_type);
      default:
        return this.$i18n.t(action.entry_type);
    }
  }

  jobTypeNameJoin(job: any) {
    return job.actions
      .map((x: any) => x.type_name)
      .filter(Boolean)
      .reverse()
      .join(", ");
  }

  formatBalance(balance: number) {
    return balance ? formatAmount(balance) : "0";
  }

  async downloadExpenseFile(expense: Record<string, any>) {
    let downloadFile = await expensesModule.getExpensionFile(expense.id);
    downloadFile = new File([downloadFile], expense.file_name);
    FileSaver.saveAs(downloadFile);
  }

  async donwloadCompletedJobPdf(job: Record<string, unknown>) {
    this.isDownloadingCompleteJobPdf = true;
    const utcOffset = dayjs().utcOffset();

    const data = {
      jobId: job.id,
      utcOffset: utcOffset,
      language: localStorage.getItem("lang"),
    };

    if (job.id) {
      const company = await companyModule.getCompanyInfo();
      const filename =
        `${company.entity_name}_${job.tag_id}_job_${job.id}.pdf`.replace(
          / /g,
          "_"
        );
      const pdf = await jobsModule.getCompletedJobPdfExportAction(data);
      const file = new File([pdf], filename);
      FileSaver.saveAs(file);
    }
    this.isDownloadingCompleteJobPdf = false;
  }

  canDeleteAction(action: any, job: any) {
    if (job.cost_disabled) return job.cost - action.cost - job.amount_paid >= 0;
    else return true;
  }

  async editJob(item: any) {
    await itemsModule.getInstallationPrimaryInventoryItems(
      item.installation_id
    );
    item.partner_id = partnersModule.currentPartner.info.partner_id;
    commonModule.showModal({ name: "edit-job-modal", payload: { job: item } });
  }

  deleteJob(item: any) {
    item.partner_id = partnersModule.currentPartner.info.partner_id;
    commonModule.showModal({
      name: "delete-job-modal",
      payload: { job: item },
    });
  }

  addActionsToJob(item: any) {
    item.partner_id = partnersModule.currentPartner.info.partner_id;
    item.partner_uuid = partnersModule.currentPartner.info.partner_uuid;
    commonModule.showModal({
      name: "add-actions-to-job-modal",
      payload: { job: item },
    });
  }

  addMaterialsToJob(item: any) {
    item.partner_id = partnersModule.currentPartner.info.partner_id;
    item.partner_uuid = partnersModule.currentPartner.info.partner_uuid;
    commonModule.showModal({
      name: "add-materials-to-job-modal",
      payload: { job: item },
    });
  }

  addExpensesToJob(item: any) {
    item.partner_id = partnersModule.currentPartner.info.partner_id;
    item.partner_uuid = partnersModule.currentPartner.info.partner_uuid;
    commonModule.showModal({
      name: "add-expense-to-job-modal",
      payload: { job: item },
    });
  }

  async addPaymentToJob(item: any) {
    item.partner_id = partnersModule.currentPartner.info.partner_id;
    item.partner_uuid = partnersModule.currentPartner.info.partner_uuid;
    this.installation = await installationsModule.getInstallationInfo(
      item.installation_id
    );
    commonModule.showModal({
      name: "add-payment-to-job-modal",
      payload: { job: item, installation: this.installation },
    });
  }

  deletePayment(payment: any, job: any) {
    commonModule.showModal({
      name: "delete-payment-modal",
      payload: { payment: payment, job: job },
    });
  }

  deleteAction(action: any, job: any) {
    commonModule.showModal({
      name: "delete-action-modal",
      payload: { action: action, job: job },
    });
  }

  deleteMaterial(action: any, job: any) {
    commonModule.showModal({
      name: "delete-material-modal",
      payload: { material: action, job: job },
    });
  }

  deleteExpense(action: any, job: any) {
    commonModule.showModal({
      name: "delete-expense-modal",
      payload: { expense: action, job: job },
    });
  }

  async editPayment(payment: any, job: any) {
    const installation = await installationsModule.getInstallationInfo(
      job.installation_id
    );
    commonModule.showModal({
      name: "edit-payment-modal",
      payload: { payment: payment, job: job, installation: installation },
    });
  }

  editAction(action: any, job: any) {
    commonModule.showModal({
      name: "edit-action-modal",
      payload: { action: action, job: job },
    });
  }

  editMaterial(action: any, job: any) {
    commonModule.showModal({
      name: "edit-material-modal",
      payload: { material: action, job: job },
    });
  }

  editExpense(action: any, job: any) {
    commonModule.showModal({
      name: "edit-expense-modal",
      payload: { expense: action, job: job },
    });
  }

  addPhoto(item: any) {
    commonModule.showModal({
      name: "add-photo-to-installation-modal",
      payload: { installation: item, tab: "jobs" },
    });
  }

  showPhotos(job: any) {
    commonModule.showModal({ name: "job-photos-modal", payload: { job: job } });
  }

  get descriptionIsEnabled() {
    return (companyModule.company?.preferences as any)?.job_description;
  }

  expansionText(job: Record<string, any>) {
    if (
      this.descriptionIsEnabled &&
      job.description &&
      job.description.length > 0
    ) {
      return job.description;
    }
    return this.jobTypeNameJoin(job);
  }

  setMergedAddressAndArea(address: string, area: string) {
    return mergedAddressAndArea(address, area);
  }
}
