import { VuexModule, Module, Action, Mutation } from 'vuex-module-decorators'
import InventoryApi from '@/api/inventory-api'
import { TimeUtil } from '@/helpers/TimeUtil'
import { commonModule } from '@/store/modules/store-accessor'
import i18n from '@/i18n';

@Module({ namespaced: true, name: "items" })
export default class ItemsModule extends VuexModule {
  public items: ItemDto[] = [];
  public installationItems: InstallationItemDto[] = [];
  public isLoadingTable = true;
  public filters = "";
  public installationPrimaryItems: ItemDto[] = [];
  public allCompanyPrimaryItems: ItemDto[] = [];
  public search = "";
  public queryObject: any = {};
  public itemsUnitsCombo = [];
  public itemsCombo = [];
  public loadingItemsCombo = true;

  @Action({ commit: "setItems" })
  async getAllInventoryItems(query: string): Promise<ItemDto[]> {
    this.setIsLoadingTable(true);
    const itemsApi = new InventoryApi();
    const items = await itemsApi.getAllInventoryItems(query);
    this.setIsLoadingTable(false);
    return items;
  }

  @Mutation
  public setItems(items: ItemDto[]): void {
    try {
      items.map((item) => {
        item.readable_created_at = `${TimeUtil.formatDateTime(
          item.created_at
        )}`;
      });
      this.items = items;
      this.installationItems = items.map((ii) => ({
        id: ii.id,
        make: ii.make,
        model: ii.model,
        name: `${ii.type_name} | ${ii.make} | ${ii.model ?? "-"}`,
        item_type_id: ii.item_type_id,
        warranty: ii.warranty,
      }));
      this.isLoadingTable = false;
    } catch (error) {
      this.isLoadingTable = false;
    }
  }

  @Action({ commit: "setInstallationPrimaryItems" })
  async getInstallationPrimaryInventoryItems(
    installationId: string
  ): Promise<ItemDto[]> {
    this.setIsLoadingTable(true);
    const itemsApi = new InventoryApi();
    const items = await itemsApi.getInstallationPrimaryInventoryItems(
      installationId
    );
    this.setIsLoadingTable(false);
    return items.map((x: any) => {
      x.hidden_id = (Math.random() + 1).toString(36).substring(7);
      return x;
    });
  }

  @Mutation
  public setInstallationPrimaryItems(
    installationPrimaryItems: ItemDto[]
  ): void {
    this.installationPrimaryItems = installationPrimaryItems;
  }

  @Action({ commit: "setAllCompanyPrimaryItems" })
  async getAllCompanyPrimaryInventoryItems(): Promise<ItemDto[]> {
    this.setIsLoadingTable(true);
    const itemsApi = new InventoryApi();
    const items = await itemsApi.getAllCompanyPrimaryInventoryItems();
    this.setIsLoadingTable(false);
    return items;
  }

  @Mutation
  public setAllCompanyPrimaryItems(allCompanyPrimaryItems: ItemDto[]): void {
    this.allCompanyPrimaryItems = allCompanyPrimaryItems;
  }

  @Mutation
  public clearItems(): void {
    this.items = [];
  }

  @Action
  async createItem(data: Record<string, any>) {
    data.item_type_id = data.item_type_id.id;
    if (data.model == "") {
      delete data.model;
    }
    if (data.model_year == "") {
      delete data.model_year;
    }
    if (data.notes == "") {
      delete data.notes;
    }
    if (data.warranty == "") {
      delete data.warranty;
    }

    if(data.is_unit_based) {
      data.unit = data.unit.enum_text;
    } else {
      delete data.unit;
    }

    const itemsApi = new InventoryApi();
    await itemsApi.createInventoryItem(data);
    await this.getAllInventoryItems(this.filters);
  }

  @Action
  async deleteItem(item: Record<string, any>) {
    const itemsApi = new InventoryApi();
    await itemsApi.deleteInventoryItem(item.id);
    await this.getAllInventoryItems(this.filters);
  }

  @Action
  async editItem(item: Record<string, any>) {
    item.item_type_id = item.item_type_id.id;

    if (item.model == "") {
      delete item.model;
    }
    if (item.model_year == "") {
      delete item.model_year;
    }
    if (item.warranty == "") {
      delete item.warranty;
    }

    if(item.is_unit_based) {
      item.unit = item.unit.enum_text;
    } else {
      delete item.unit;
    }

    const itemsApi = new InventoryApi();
    await itemsApi.updateInventoryItem(item.id, item);
    await this.getAllInventoryItems(this.filters);
  }

  @Mutation
  public setIsLoadingTable(isLoadingTable: boolean): void {
    this.isLoadingTable = isLoadingTable;
  }

  @Mutation
  public setFilters(filters: string): void {
    this.filters = filters;
  }

  @Mutation
  public setQueryObject(queryObject: any): void {
    this.queryObject = queryObject;
  }

  @Mutation
  public setSearch(search: string): void {
    this.search = search;
  }

  @Action({ commit: 'setItemsUnitsCombo'})
  async getItemsUnitsCombo (): Promise<any> {
    const itemsApi = new InventoryApi()
    this.setItemsComboLoader(true);
    const itemsUnitsCombo = await itemsApi.getInventoryItemsUnitsCombo();
    this.setItemsComboLoader(false);

    return itemsUnitsCombo;
  }

  @Mutation
  public setItemsUnitsCombo (itemsUnitsCombo: any): void {
    const acceptDecimals = ['METERS', 'CENTIMETERS', 'SQUAREMETERS']; // PIECES is not here

    this.itemsUnitsCombo = itemsUnitsCombo.map((unit: any) => (
      { text: i18n.t(unit.item_units.toLowerCase()),
        enum_text: unit.item_units,
        decimals: acceptDecimals.includes(unit.item_units)
      }));
  }

  @Action({ commit: 'setItemsCombo'})
  async getItemsCombo (): Promise<any> {
    const itemsApi = new InventoryApi()
    const itemsCombo = await itemsApi.getInventoryItemsCombo();
    return itemsCombo;
  }

  @Mutation
  public setItemsCombo (itemsCombo: any): void {
    this.itemsCombo = itemsCombo;
  }

  @Mutation
  public setItemsComboLoader (loadingItemsCombo: boolean): void {
    this.loadingItemsCombo = loadingItemsCombo;
  }

}
