
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { commonModule, clientsModule, companyModule } from '@/store/modules/store-accessor'
import { TimeUtil } from '@/helpers/TimeUtil'
import { LMap, LTileLayer, LMarker, LControlLayers } from 'vue2-leaflet';
import { Icon } from "leaflet";
import { isGasCompany, mapTileProviders } from "@/helpers/data";

Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});


@Component({
  components: {
    LMap, LTileLayer, LMarker, LControlLayers
  }
})
export default class InstallationsSideviewGeneralTab extends Vue {
 @Prop() installation: Record<string, any>;

 center = [0, 0];
 zoom = 14;
 markerCoords = [0, 0];

 mapTileProviders() {
  return mapTileProviders();
 }

  formatDate (date: Date): string {
    return TimeUtil.formatDateTime(date)
  }

  @Watch("installation.info.latitude")
  updateMap() {
    this.center = [this.installation.info.latitude, this.installation.info.longitude];
    this.markerCoords = [this.installation.info.latitude, this.installation.info.longitude];
  }

  async mounted() {
    this.center = [this.installation.info.latitude, this.installation.info.longitude];
    this.markerCoords = [this.installation.info.latitude, this.installation.info.longitude];

    await companyModule.getCompanyInfo();
  }

  async selectClient (clientId: string) {
    await clientsModule.getClientsInfo(clientId)
    commonModule.showSideview({ name: 'clients-sideview' , payload: { clientId: clientId} })
  }

 ownerInfoText() {
  if(!this.installation.info.owner_firstname ) {
    return '-';
  }
  if(this.installation.info.owner_is_business) {
    return `${this.installation.info.owner_entity_name} (${this.installation.info.owner_lastname} ${this.installation.info.owner_firstname})`;
  }
  return  this.installation.info.owner_lastname + ' ' + this.installation.info.owner_firstname;
  }

  clientInfoText() {
  if(!this.installation.info.client_firstname ) {
    return '-';
  }
  if(this.installation.info.client_is_business) {
    return `${this.installation.info.client_entity_name} (${this.installation.info.client_lastname} ${this.installation.info.client_firstname})`;
  }
  return  this.installation.info.client_lastname + ' ' + this.installation.info.client_firstname;
  }

  get descriptionIsEnabled() {
    return (companyModule.company?.preferences as any)?.installation_description;
  }

  showTypeAndFuel() {
    return isGasCompany(companyModule.company);
  }

  get installationArea() {
    return `${this.installation.info.area}${this.installation.info.zip_code ? `, ${this.installation.info.zip_code}` : ''}`;
  }

};
