
import { Component, Vue, Watch } from "vue-property-decorator";
import {
  commonModule,
  installationsModule,
  filterModule,
  companyModule,
  itemsModule,
} from "@/store/modules/store-accessor";
import Dropdown from "../components/dropdown/Dropdown.vue";
import DropdownItem from "../components/dropdown/DropdownItem.vue";
import FilterDropdown from "../components/dropdown/filter/FilterDropdown.vue";
import InstallationFilter from "../components/Filter/InstallationFilter.vue";
import {
  formatAmount,
  sortTableDates,
  isGasCompany,
  mergedAddressAndArea,
} from "@/helpers/data";

@Component({
  components: { Dropdown, DropdownItem, FilterDropdown, InstallationFilter },
})
export default class Installations extends Vue {
  tableheaders: any[] = [
    {
      text: "",
      align: "left",
      class: "row-style",
      sortable: false,
      value: "is_premium",
      width: "10px",
    },
    {
      text: this.$t("tag"),
      align: "left",
      class: "row-style",
      sortable: true,
      value: "tag_split",
    },
    {
      text: this.$t("companyName"),
      sortable: true,
      class: "row-style",
      value: "client_name",
    },
    {
      text: this.$t("address"),
      sortable: true,
      class: "row-style",
      value: "address",
    },
    {
      text: this.$t("itemTypeText"),
      sortable: true,
      class: "row-style",
      value: "primary_item_types",
    },
    {
      text: this.$t("boiler_make"),
      sortable: true,
      class: "row-style",
      value: "primary_makes",
    },
    {
      text: this.$t("boiler_model"),
      sortable: true,
      class: "row-style",
      value: "primary_models",
    },
    {
      text: this.$t("notes"),
      sortable: true,
      class: "row-style",
      value: "notes",
    },
    {
      text: this.$t("balance"),
      align: "left",
      sortable: true,
      class: "row-style",
      value: "balance",
    },
    {
      text: "",
      align: "right",
      sortable: false,
      class: "row-style",
      value: "id",
    },
  ];

  model: any = {
    search: "",
    selected: [] as string[],
  };

  timer: number;

  onSearchChange(val: string) {
    clearTimeout(this.timer);

    this.timer = setTimeout(async () => {
      installationsModule.setIsLoadingTable(true);
      if (val && val.length > 0) {
        installationsModule.setSearch(val);
        installationsModule.queryObject.search = installationsModule.search;
      } else {
        installationsModule.setSearch("");
        delete installationsModule.queryObject.search;
      }
      this.$router.replace({ query: installationsModule.queryObject });

      // Check if user selected any of the queries
      const queryIndex = this.$route.fullPath.indexOf("?");
      if (queryIndex >= 0) {
        const query = this.$route.fullPath.substring(queryIndex);
        installationsModule.setFilters(query);
      } else {
        installationsModule.setFilters("");
      }

      await installationsModule.getInstallationsAction(
        installationsModule.filters
      );
      installationsModule.setIsLoadingTable(false);
    }, 500);
  }

  get installationsLength() {
    return installationsModule.installations.length;
  }

  get searchLabel() {
    return this.$t("installationsSearch");
  }

  get installations() {
    return installationsModule.installations;
  }

  get currInstallation() {
    return installationsModule.currentInstallation;
  }

  get isLoadingTable() {
    return installationsModule.isLoadingTable;
  }

  formatBalance(balance: number) {
    return formatAmount(balance);
  }

  async mounted() {
    /*
     TODO: Handle installations properly when UX is clear.
     */
    installationsModule.getInstallationsCount();
    installationsModule.getInstallationsAction("");

    // Clear the search input and all of the other filters when visiting the page
    installationsModule.setSearch("");
    installationsModule.setFilters("");
    installationsModule.setQueryObject({});

    filterModule.getInstallationsFilters();
    commonModule.getPaymentTypeCombo();
    await companyModule.getCompanyInfo();
    commonModule.initSnackbar({});

    if (isGasCompany(companyModule.company)) {
      this.tableheaders.splice(8, 0, {
        text: this.$t("fuel_type"),
        align: "left",
        sortable: true,
        class: "row-style",
        value: "primary_fuel_types",
      });
    }
    if ((companyModule.company?.preferences as any)?.installation_description) {
      this.tableheaders.splice(2, 0, {
        text: this.$t("description"),
        sortable: true,
        class: "row-style",
        value: "description",
      });
    }
  }

  destroyed() {
    installationsModule.clearInstallations();
  }

  async selectInstallation(installation: any) {
    try {
      installationsModule.setIsLoadingTable(true);
      await installationsModule.getInstallationInfo(installation.id);
      await itemsModule.getInstallationPrimaryInventoryItems(installation.id);
      installationsModule.setIsLoadingTable(false);
      commonModule.showSideview({
        name: "installations-sideview",
        payload: { installationId: installation.id },
      });
    } catch (error) {
      installationsModule.setIsLoadingTable(false);
    }
  }

  async addInstallation() {
    await installationsModule.getInstallationNextTagId();
    commonModule.showModal({ name: "add-installation-modal" });
  }

  deleteInstallation(installation: any) {
    commonModule.showModal({
      name: "delete-installation-modal",
      payload: { installation: installation },
    });
  }

  async editInstallation(installation: any) {
    await installationsModule.getInstallationInfo(installation.id);
    commonModule.showModal({
      name: "edit-installation-modal",
      payload: { view: "installations" },
    });
  }

  async refreshData() {
    await installationsModule.getInstallationsCount();
    await installationsModule.getInstallationsAction(
      installationsModule.filters
    );
    await filterModule.getInstallationsFilters();
  }

  get descriptionIsEnabled() {
    return (companyModule.company?.preferences as any)
      ?.installation_description;
  }

  setMergedAddressAndArea(address: string, area: string) {
    return mergedAddressAndArea(address, area);
  }
}
