
import Vue from "vue";
import { Component } from "vue-property-decorator";
import Header from "@/components/Header/Header.vue";
import Sidebar from "@/components/Sidebar/Sidebar.vue";
import Footer from "@/components/Footer/Footer.vue";
import TheModal from "@/components/TheModal.vue";
import TheSideview from "@/components/TheSideview.vue";
import TheSnackbar from "@/components/TheSnackbar.vue"
import TheSecondModal from "../TheSecondModal.vue";
import TheThirdModal from "../TheThirdModal.vue";

@Component({
  components: { Footer, Header, Sidebar, TheModal, TheSideview, TheSnackbar, TheSecondModal, TheThirdModal },
})
export default class Layout extends Vue {
  closeOnClick = true;
  items_actions = [
    { title: this.$i18n.t("view"), route: "installations" },
    { title: this.$i18n.t("edit"), route: "installations" },
    { title: this.$i18n.t("delete"), route: "installations" },
  ];
}
