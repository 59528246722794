import { VuexModule, Module, Action, Mutation } from "vuex-module-decorators";
import JobsApi from "@/api/jobs-api";
import { TimeUtil } from "@/helpers/TimeUtil";
import dayjs from "dayjs";
import {
  installationsModule,
  partnersModule,
} from "@/store/modules/store-accessor";
import _ from "lodash";
@Module({ namespaced: true, name: "jobs" })
export default class JobsModule extends VuexModule {
  public jobs: JobDto[] = [];
  public currJob: JobDto = {} as JobDto;
  public isLoadingTable = true;
  public filters = "";
  public jobTotals: any = {};
  public queryObject: any = {};
  public search = "";

  @Action
  async getJobPendingBalance(jobId: string): Promise<number> {
    const jobApi = new JobsApi();
    const pendingBalance = await jobApi.getJobPendingBalance(jobId);
    return pendingBalance.pending;
  }

  @Action({ commit: "setJobInfo" })
  async getJobs(query: string): Promise<JobDto[]> {
    this.setIsLoadingTable(true);
    const jobApi = new JobsApi();
    const jobs = await jobApi.getAllJobs(query);

    return jobs;
  }

  @Action({ commit: "setJobTotals" })
  async getJobTotals(query: string): Promise<JobDto[]> {
    const jobApi = new JobsApi();
    const jobTotals = await jobApi.getJobsTotals(query);
    return jobTotals;
  }

  @Action({ commit: "setJobDetails" })
  async getJobDetails(currentJobId: string): Promise<JobDto> {
    const jobApi = new JobsApi();
    const jobInfo = await jobApi.getJob(currentJobId);
    return jobInfo;
  }

  @Action
  async createJobWithActions(data: Record<string, any>) {
    const jobData = _.cloneDeep(data);

    jobData.assignee_id = data.assignee_id.id;
    jobData.actions = data.type_id;
    jobData.client_paid = data.radioValue == 'user' ? true : false;
    jobData.payment_type_id = data.payment_type?.id;
    if (!jobData.is_paid) {
      delete jobData.payment_amount;
      delete jobData.payment_type_id;
    }
    if (data.installation_item_id && data.installation_item_id.id) {
      jobData.item_type_text = jobData.installation_item_id.item_type_text;
      jobData.item_make = jobData.installation_item_id.item_make;
      jobData.item_model = jobData.installation_item_id.item_model;
      jobData.item_serial_no = jobData.installation_item_id.item_serial_no;
      jobData.installation_item_id = data.installation_item_id.id;
    } else {
      delete jobData.installation_item_id;
    }

    jobData.actions.map((item: Record<string, any>) => {
      item.unit = item.is_time_charged ? item.unit.unit : null;
    });

    delete jobData.is_paid;
    delete jobData.radioValue;
    delete jobData.type_id;
    delete jobData.payment_type;

    const jobApi = new JobsApi();

    const jobId = await jobApi.createJobWithActions(jobData);
    await installationsModule.getInstallationInfo(data.installation_id);
    if (jobData.partner_id) {
      partnersModule.getPartnersInfo(jobData.partner_id);
    }
    return jobId;
  }

  @Action
  async editJob(data: Record<string, any>) {
    const job_data = _.cloneDeep(data);

    //make cost null for new jobs. Keep cost for legacy jobs.
    if (job_data.cost_disabled) job_data.cost = null;

    if (job_data.installation_item_id && job_data.installation_item_id.id) {
      job_data.item_type_text = job_data.installation_item_id.item_type_text;
      job_data.item_make = job_data.installation_item_id.item_make;
      job_data.item_model = job_data.installation_item_id.item_model;
      job_data.item_serial_no = job_data.installation_item_id.item_serial_no;
      job_data.installation_item_id = job_data.installation_item_id.id;
    } else {
      delete job_data.installation_item_id;
    }

    const jobApi = new JobsApi();
    await jobApi.updatejob(job_data.id, job_data);
    await installationsModule.getInstallationInfo(data.installation_id);
    if (data.partner_id) {
      partnersModule.getPartnersInfo(data.partner_id);
    }
  }

  @Action
  async deleteJob(data: Record<string, any>) {
    const jobApi = new JobsApi();
    await jobApi.deleteJob(data.id);
    await installationsModule.getInstallationInfo(data.installation_id);
    if (data.partner_id) {
      partnersModule.getPartnersInfo(data.partner_id);
    }
  }

  @Action
  async createAppointmentToJob(data: Record<string, any>) {
    data.assignee_id = data.assignee_id.id;
    data.type_id = data.type_id.id;
    data.duration = data.duration.dur;
    data.scheduled_start = data.scheduled_start
      ? dayjs(data.scheduled_start + " " + data.scheduled_start_time)
      : null;
    delete data.scheduled_start_time;

    const jobApi = new JobsApi();
    await jobApi.createJobAppointment(data.id, data);
    await installationsModule.getInstallationInfo(data.installation_id);
  }

  @Action
  async createActionsToJob(data: Record<string, any>) {
    data.assignee_id = data.assignee_id.id;
    data.actions = data.type_id;
    delete data.type_id;

    if (data.created_at_date && data.created_at_time) {
      data.created_at = dayjs(
        data.created_at_date + " " + data.created_at_time
      );
      delete data.created_at_date;
      delete data.created_at_time;
    }

    data.actions.map((item: Record<string, any>) => {
      item.unit = item.is_time_charged ? item.unit.unit : null;
    });

    const jobApi = new JobsApi()
    await jobApi.createJobActions(data.id, data)
    await installationsModule.getInstallationInfo(data.installation_id);

    // Update partners sideview (jobs tab)
    if (
      Object.keys(partnersModule.currentPartner).length > 0 &&
      partnersModule.currentPartner.info.partner_id
    ) {
      await partnersModule.getPartnersInfo(
        partnersModule.currentPartner.info.partner_id
      );
    }
  }

  @Action
  async createMaterialsToJob(data: Record<string, any>) {
    const material_data = _.cloneDeep(data);

    material_data.assignee_id = material_data.assignee_id.id;

    if (material_data.created_at_date && material_data.created_at_time) {
      material_data.created_at = dayjs(
        material_data.created_at_date + " " + material_data.created_at_time
      );
      delete material_data.created_at_date;
      delete material_data.created_at_time;
    }

    const jobApi = new JobsApi();
    await jobApi.createJobMaterials(material_data.id, material_data);
    await installationsModule.getInstallationInfo(
      material_data.installation_id
    );

    // Update partners sideview (jobs tab)
    if (data.partner_id) {
      await partnersModule.getPartnersInfo(data.partner_id);
    }
  }

  @Action
  async createExpensesToJob(data: Record<string, any>) {
    const expensesData = _.cloneDeep(data);

    expensesData.assignee_id = expensesData.assignee_id.id;
    expensesData.payment_type_id = expensesData.payment_type.id;

    if (expensesData.created_at_date && expensesData.created_at_time) {
      expensesData.created_at = dayjs(
        expensesData.created_at_date + " " + expensesData.created_at_time
      );
      delete expensesData.created_at_date;
      delete expensesData.created_at_time;
    }

    const jobApi = new JobsApi();
    const expenseId = await jobApi.createJobExpenses(
      expensesData.id,
      expensesData
    );
    installationsModule.getInstallationInfo(expensesData.installation_id);
    // Update expenses sideview (jobs tab)
    if (data.partner_id) {
      await partnersModule.getPartnersInfo(data.partner_id);
    }
    return expenseId;
  }

  @Action
  async createPaymentToJob(data: Record<string, any>) {
    data.assignee_id = data.assignee_id.id;
    data.client_id = data.client.id;
    data.payment_type_id = data.payment_type.id;

    if (data.created_at_date && data.created_at_time) {
      data.created_at = dayjs(
        data.created_at_date + " " + data.created_at_time
      );
      delete data.created_at_date;
      delete data.created_at_time;
    }

    const jobApi = new JobsApi();
    await jobApi.createJobPayment(data.id, data);
    await installationsModule.getInstallationInfo(data.installation_id);

    if (data.partner_id) {
      await partnersModule.getPartnersInfo(data.partner_id);
    }
  }

  @Action
  async getCompletedJobPdfExportAction(data: Record<string, any>) {
    const jobApi = new JobsApi();
    const response = await jobApi.getCompletedJobPdfExport(data);
    const blob = new Blob([response], { type: "application/pdf" });
    return blob;
  }

  @Action
  async getJobsPdfExportAction({
    query,
    data,
  }: {
    query: string;
    data: Record<string, any>;
  }) {
    const jobApi = new JobsApi();
    const response = await jobApi.getJobsPdfExport(query, data);
    const blob = new Blob([response], { type: "application/pdf" });
    return blob;
  }

  @Mutation
  public setJobInfo(jobs: JobDto[]): void {
    try {
      jobs.map((item) => {
        item.readable_created_at = `${TimeUtil.formatDateTime(
          item.created_at
        )}`;
        item.readable_updated_at = `${TimeUtil.formatDateTime(
          item.updated_at
        )}`;
        item.readable_last_job_entry_at = `${TimeUtil.formatDateTime(
          item.last_job_entry_at
        )}`;
      });
      this.jobs = jobs;
      this.isLoadingTable = false;
    } catch (error) {
      this.isLoadingTable = false;
    }
  }

  @Mutation
  public clearJobInfo(): void {
    this.jobs = [];
    this.jobTotals = {};
  }

  @Mutation
  public setJobTotals(jobTotals: any): void {
    this.jobTotals = jobTotals;
  }

  @Mutation
  public setJobDetails(job: JobDto): void {
    this.currJob = job;
  }

  @Mutation
  public setIsLoadingTable(isLoadingTable: boolean): void {
    this.isLoadingTable = isLoadingTable;
  }

  @Mutation
  public setFilters(filters: any): void {
    this.filters = filters;
  }

  @Mutation
  public setQueryObject(queryObject: any): void {
    this.queryObject = queryObject;
  }

  @Mutation
  public setSearch(search: string): void {
    this.search = search;
  }
}
