
  import { Component, Vue } from 'vue-property-decorator'
  import EditJobPhotoModal from './Modals/Photos/EditJobPhotoModal.vue'
  import DeleteJobPhotoModal from './Modals/Jobs/DeleteJobPhotoModal.vue'
  import CompleteTaskModal from './Modals/Tasks/CompleteTaskModal.vue'
  import EditTaskModal from '@/components/Modals/Tasks/EditTaskModal.vue'
  import DeleteTaskModal from './Modals/Tasks/DeleteTaskModal.vue'
  import DeleteExpenseModal from '@/components/Modals/Jobs/DeleteExpenseModal.vue'
  import EditExpenseModal from './Modals/Jobs/EditExpenseModal.vue'
  import QuickAddCalendarModal from './Modals/Appointments/QuickAddCalendarModal.vue'

  import { commonModule } from '@/store/modules/store-accessor'
  
  @Component({
    components: {
      EditJobPhotoModal,
      DeleteJobPhotoModal,
      CompleteTaskModal,
      EditTaskModal,
      DeleteTaskModal,
      DeleteExpenseModal,
      EditExpenseModal,
      QuickAddCalendarModal,
    }
  })
  export default class TheSecondModal extends Vue {
    get modalName () {
      return commonModule.secondaryModal.name
    }
  
    get modalPayload () {
      return commonModule.secondaryModal.payload
    }
  };
  