
  import { sortDateRange } from "@/helpers/data";
  import { filterModule, appointmentsModule, dashboardModule, commonModule } from "@/store/modules/store-accessor";
  import Vue from "vue";
  import { Component } from "vue-property-decorator";
  import FilterList from "./FilterList.vue";
  import DatePickerFilter from "./DatePickerFilter.vue";
  import dayjs from "dayjs";
  
  @Component({
    components: {
      FilterList,
      DatePickerFilter
    },
  })
  export default class DashboardPendingTasksFilter extends Vue {
    selectTasksFilterOption = { text: this.$i18n.t("today"), days: 0  };

    tasksFilterOptions = [
      { text: this.$i18n.t("today"), days: 0 },
      { text: this.$i18n.t("week"), days: 7 },
      { text: this.$i18n.t("month"), days: 30 },
      { text: this.$i18n.t("all"), days: null },
    ];

    get dashboardTasksFilters() {
      if((filterModule.dashboardTasksFilters as any).taskTypes) {
        (filterModule.dashboardTasksFilters as any).taskTypes = (filterModule.dashboardTasksFilters as any).taskTypes.map((task: any) => {
        if (task.task_category_type === 'REMINDER') {
          task.task_category_type = this.$i18n.t('reminder_label');
        }
        return task;
      });
      }

      return filterModule.dashboardTasksFilters;
    }
    async mounted() {
      filterModule.getDashboardTasksFilters();
      this.selectTasksFilterOption = this.tasksFilterOptions[0] as any;
    }
  
    selectedActionTypes: string[];
    selectedUsers: string[];
    
    updateActionTypes(data: string[]) {
      this.selectedActionTypes = data;
      this.submit();
    }

    selectedCategoryTypes: string[];
    
    updateCategoryTypes(data: string[]) {
      this.selectedCategoryTypes = data;
      this.submit();
    }

    updateTimeSpan(data: any) {
      this.selectTasksFilterOption = data;
      this.submit();
    }

    updateUsers(data: string[]) {
      this.selectedUsers = data;
      this.submit();
    }
  
    async submit() {
      dashboardModule.setIsLoadingPendingTasks(true);
      let query: any = {};
  
      let actionsTempId;
      let actionsIds = [];
      let userIds = [];
      let tempId;

      if (this.selectTasksFilterOption.days != null) {
        query["date_from"] = dayjs().startOf('day').toISOString() //IsoString gives time in ISO string time in browser local time
        query["date_to"] = dayjs().endOf('day').add(this.selectTasksFilterOption.days!, 'days').toISOString()//IsoString gives time in ISO string time in browser local time
      }
      else {
        delete query["date_from"];
        delete query["date_to"];
      }

      if (this.selectedActionTypes != undefined) {
        for (let i = 0; i < this.selectedActionTypes.length; i++) {
          actionsTempId = (this.selectedActionTypes[i] as any).id
          actionsIds.push(actionsTempId);
        }
      }
  
      if (actionsIds != undefined && actionsIds.length > 0) {
        query["actions[]"] = actionsIds;
      }


      let categoriesTempId;
      let categoriesIds = [];

      if (this.selectedCategoryTypes != undefined) {
        for (let i = 0; i < this.selectedCategoryTypes.length; i++) {
          categoriesTempId = (this.selectedCategoryTypes[i] as any).id
          categoriesIds.push(categoriesTempId);
        }
      }
  
      if (categoriesIds != undefined && categoriesIds.length > 0) {
        query["categories[]"] = categoriesIds;
      }

      if (this.selectedUsers != undefined) {
        for (let i = 0; i < this.selectedUsers.length; i++) {
          tempId = (this.selectedUsers[i] as any).id
          userIds.push(tempId);
        }
      }
    
      if (userIds != undefined && userIds.length > 0) {
        query["assignee_id[]"] = userIds;
      }
  
      this.$router.replace({ query: query }).catch(err => {/* */ });
  
      // Check if user selected any of the queries
      const queryIndex = this.$route.fullPath.indexOf('?');
      if (queryIndex >= 0) {
        const query = this.$route.fullPath.substring(queryIndex);
        dashboardModule.setTaskFilters(query);
      } else {
        dashboardModule.setTaskFilters('');
      }

      await filterModule.getDashboardTasksFilters();
      await dashboardModule.getDashboardPendingTasks(dashboardModule.taskFilters);
      dashboardModule.setIsLoadingPendingTasks(false);
    }
  }
  