
import { Component, Vue, Prop } from 'vue-property-decorator'
import { commonModule, appointmentsModule, actionTypesModule, itemsModule, companyModule, dashboardModule, tasksModule } from '@/store/modules/store-accessor'
import { getOptimalTextColor } from "@/helpers/data";
import AssigneeUserCombo from '@/components/Combobox/AssigneeUserCombo.vue';
import AssigneeMultiUserCombo from '@/components/Combobox/AssigneeMultiUserCombo.vue';
import AssigneeCombo from "@/components/Combobox/AssigneeCombo.vue";
import _ from 'lodash';

@Component({
  components: {
    AssigneeUserCombo,
    AssigneeMultiUserCombo,
    AssigneeCombo
  },
})
export default class AddToCalendarModal extends Vue {
  @Prop()
  payload!: any;

  isAddingAppointment = false;
  isAddingTask = false;

  selectedOption = 'appointment';
  appointmentSelectAption = '';
  taskSelectAption = '';

  loadingPersonnel = true;
  loadingInstallationPrimaryItems = true;
  disableInstallationPrimaryItems = true;

  appointmentDateMenu = false;
  appointmentTimeMenu = false;
  loadingInstallations = true;

  payloadAssignee = {} as any;
  payloadAssigneeTask = {} as any;

  jobModel = {
    type_id: { text: "", color: "", cost: 0, id: 0 },
    assignee_id: this.payload.assignee_id ? { name: this.payload.assignee_id.name, id: this.payload.assignee_id.id } : "",
    assignee_ids: [] as any[],
    appointment_is_confirmed: true,
    appointment_status_id: 2,
    scheduled_start: this.payload.scheduled_start,
    scheduled_start_time: this.payload.scheduled_start_time,
    duration: this.durationCombo[1] || '',
    appointment_notes: this.payload.notes || '',
    installation_id: null as any,
    task_id: this.payload.task_id,
    installation_item_id: this.getItemText(this.payload.installation_item_id),
    description: ''
  }

  scheduledDateRule = [
    (val: any) => !!val || this.$i18n.t("scheduled_day_required"),
  ]

  userRule = [
    (val: any) => !!val || this.$i18n.t("user_required"),
  ]

  scheduledTimeRule = [
    (val: any) => !!val || this.$i18n.t("scheduled_time_required"),
  ]

  durationRule = [
    (val: any) => !!val || this.$i18n.t("duration_required"),
  ]
  typeRule = [
    (val: any) => !!val && val.text != "" || this.$i18n.t("action_required"),
  ]
  installationRule = [
    (val: any) => !!val || this.$i18n.t("installation_required"),
  ]
  notesRule = [
    (val: any) => {
      if (val != null) return val.length <= 1000 || this.$i18n.t("notesLengthRule");
      else return true;
    },
  ]
  descriptionRule = [
    (val: any) => val.length <= 80 || this.$i18n.t("jobDescriptionLengthRule"),
  ]

  get installtionPrimaryItems() {
    return itemsModule.installationPrimaryItems;
  }

  get disablePrimaryItems() {
    return this.loadingInstallationPrimaryItems || this.installtionPrimaryItems.length === 0;
  }

  get durationCombo() {
    return commonModule.durationCombo;
  }

  get actionsCombo() {
    return actionTypesModule.actionTypesCombo;
  }

  get installationsCombo() {
    return commonModule.installationsCombo;
  }

  get isLoadingInstallations() {
    return this.loadingInstallations;
  }

  get isLoadingPersonnel() {
    return this.loadingPersonnel;
  }

  get isLoadingInstallationPrimaryItems() {
    return this.loadingInstallationPrimaryItems;
  }

  optimalTextColor(color: string) {
    return getOptimalTextColor(color);
  }

  installationText(item: any) {
    let installation_arr = item.is_business
      ?
      [item.tag_id.split('_').pop(), `${item.address ? item.address : item.area}`, (item.description && this.installationDescriptionIsEnabled) ? item.description : '', `${item.entity_name} (${item.client_name})`, item.phone]
      :
      [item.tag_id.split('_').pop(), `${item.address ? item.address : item.area}`, (item.description && this.installationDescriptionIsEnabled) ? item.description : '', item.client_name, item.phone]

    return installation_arr.filter(Boolean).join(' | ')
  }

  async setActionTypeFromId(action_type_id: any) {
    const foundType = actionTypesModule.actionTypesCombo.find((item: any) => item.id === action_type_id);
    if (foundType) {
      this.jobModel.type_id = { text: foundType.text, color: foundType.color, cost: foundType.cost, id: foundType.id } as any;
    }
  }

  taskDateMenu = false;
  taskTimeMenu = false;

  isCompleted = false;


  taskModel = {
    assignee_id: this.payload.assignee_id ? { name: this.payload.assignee_id.name, id: this.payload.assignee_id.id } : '',
    installation_id: null as any,
    task_status_id: -1,
    title: '',
    notes: '',
    action_type_id: null,
    installation_item_id: { primary_items: '', id: 0 },
    due_at: this.payload.scheduled_start || '',
    scheduled_start_time: this.payload.scheduled_start_time || '',
    all_day: false,
  };

  get showTimeInput() {
    return !this.taskModel.all_day;
  }

  titleRule = [
    (val: any) => !!val || this.$i18n.t("title_required"),
    (val: any) => val.length <= 300 || this.$i18n.t("form_title_length_rule"),
  ];
  scheduledTimeRules() {
    if (this.taskModel.due_at) {
      return [(val: any) => !!val || this.$i18n.t("timeRequired")];
    }
    return [];
  }

  clearDatetime() {
    this.taskModel.scheduled_start_time = "";
    this.taskModel.all_day = false;
  }

  async hideModal() {
    commonModule.hideModal();
    itemsModule.setInstallationPrimaryItems([]);
    appointmentsModule.setAddCalendarInstallation('');
  }

  async created() {
    await actionTypesModule.getActionTypesCombo();
    await this.setActionTypeFromId(this.payload.action_type_id);
  }

  async mounted() {
    itemsModule.setInstallationPrimaryItems([]);

    if (this.payload.assignee_id && this.payload.assignee_id.name) {
      this.payloadAssignee = this.getAssigneeId(this.payload.assignee_id.name);
      this.payloadAssigneeTask = this.getAssigneeId(this.payload.assignee_id.name)[0];
    } else {
      this.payloadAssignee = '';
      this.payloadAssigneeTask = '';
    }

    commonModule.initSnackbar({});
    await commonModule.getActionCombo();
    await commonModule.getDurationCombo();
    await commonModule.getPersonnelCombo();
    await actionTypesModule.getActionTypesCombo();


    this.loadingInstallations = true;
    await commonModule.getInstallationsCombo();
    this.loadingInstallations = false;

    // Stop the loader but keep it disabled until the user selects an installation
    this.loadingInstallationPrimaryItems = false;
    this.disableInstallationPrimaryItems = true;
  }

  defaultInstallationItem() {
    if (this.installtionPrimaryItems && this.installtionPrimaryItems.length === 1) {
      return { primary_items: this.installtionPrimaryItems[0].primary_items, id: this.installtionPrimaryItems[0].id };
    }
    return { primary_items: '', id: 0 };
  }

  updateAppointmentAssignee(assignees: any[]) {
    if (assignees.length > 0) {
      this.jobModel.assignee_id = assignees[0];
    }
    this.jobModel.assignee_ids = assignees;
  }

  updateTaskAssignee(assignee: any) {
    this.taskModel.assignee_id = assignee;
  }

  getAssigneeId(name: string) {
    const combo: any = commonModule.personnelCombo;
    const foundItem = combo.find((item: any) => item.name === name);
    if (foundItem) {
      return [{ name: foundItem.name, id: foundItem.id }];
    } else {
      return [];
    }
  }
  getInstallationId(selectedInstallationId: string) {
    if (selectedInstallationId) {
      this.loadingInstallationPrimaryItems = true;
      this.disableInstallationPrimaryItems = true;
      itemsModule.getInstallationPrimaryInventoryItems(selectedInstallationId);
      appointmentsModule.setAddCalendarInstallation(selectedInstallationId);
      this.loadingInstallationPrimaryItems = false;
      this.disableInstallationPrimaryItems = false;
    }
    else {
      itemsModule.setInstallationPrimaryItems([])
    }
  }

  getItemText(ids: number) {
    const idArray = Array.isArray(ids) ? ids : [ids];
    const arr: any = itemsModule.allCompanyPrimaryItems

    for (let i = 0; i < arr.length; i++) {
      if ((Array.isArray(arr[i].id) && arr[i].id.some((item: any) => idArray.includes(item))) || (!Array.isArray(arr[i].id) && idArray.includes(arr[i].id))) {
        (itemsModule.installationPrimaryItems as any).push({ primary_items: arr[i].primary_items, id: ids })
        return { primary_items: arr[i].primary_items, id: ids };
      }
    }
    return { primary_items: '', id: 0 };
  }

  async doAdd() {
    if ((this.$refs.form as Vue & { validate: () => boolean }).validate()) {
      // Appointment
      if (this.selectedOption === 'appointment') {
        this.isAddingAppointment = true;
        if (typeof this.jobModel.assignee_id === 'undefined' || this.jobModel.assignee_id == '' || this.jobModel.assignee_id == null) {
          this.jobModel.assignee_id = '';
        }
        await appointmentsModule.createAppointment(this.jobModel);
        this.isAddingAppointment = false;
      }

      // Task
      if (this.selectedOption === 'task') {
        let taskModelCopy = _.cloneDeep(this.taskModel);
        this.isAddingTask = true;
        taskModelCopy.task_status_id = this.isCompleted ? 3 : 2; // -> 3 completed -> 2 pending
        await tasksModule.addTask(taskModelCopy);
        this.isAddingTask = false;
      }

      this.hideModal()
      appointmentsModule.getCalendarAppointmentsAction(appointmentsModule.filters);
    }
  }

  get installationDescriptionIsEnabled() {
    return (companyModule.company?.preferences as any)?.installation_description;
  }

  get jobDescriptionIsEnabled() {
    return (companyModule.company?.preferences as any)?.job_description;
  }

  get calendarNewInstallation() {
    this.taskModel.installation_id = appointmentsModule.addCalendarInstallation;
    this.jobModel.installation_id = appointmentsModule.addCalendarInstallation;

    return appointmentsModule.addCalendarInstallation;
  }

  set calendarNewInstallation(value) {
    this.taskModel.installation_id = value;
    this.jobModel.installation_id = value;
  }

  openAddClientAndInstallationModal() {
    commonModule.showSecondModal({ name: 'quick-add-calendar-modal', payload: { isTask: this.selectedOption === 'task' } });
  }

};
