
    import { sortDateRange } from "@/helpers/data";
    import { companyModule, filterModule, tasksModule } from "@/store/modules/store-accessor";
    import Vue from "vue";
    import { Component } from "vue-property-decorator";
    import FilterList from "./FilterList.vue";
    import DatePickerFilter from "./DatePickerFilter.vue";
    import dayjs from "dayjs";
    
    @Component({
      components: {
        FilterList,
        DatePickerFilter,
      },
    })
    export default class TasksFilter extends Vue {

      get tasksFilters() {
        return filterModule.tasksFilters;
      }

      get deadLineOptions() {
        const options = (filterModule.tasksFilters as Record<string, any>).deadLineOptions;
        return options ? options.map((option: string) => ({ value: option, text: this.$t(option)})) : [];
      }

       get taskTypeOptions() {
        const options = (filterModule.tasksFilters as Record<string, any>).taskTypes;

        return options ? options.map((option: any) => {
          if (option.task_category_type === 'REMINDER') {
            option.task_category_type = this.$i18n.t('reminder_label');
          }
          return option;
        }) : [];
        }

      async mounted() {
        filterModule.getTasksFilters();
      }

      selectedUsers: string[];
      selectedActionTypes: string[];
      selectedDates: any = [dayjs().startOf('day').toISOString(), dayjs().endOf('day').toISOString()] // Set default date range to "today"
      selectedStatuses: any[];
      selectedAreas: string[];
      selectedDeadLineOption: string[];
      selectedCategoryTypes: string[];


      get taskFiltersUsers() {
        const users = (this.tasksFilters as any)?.users;
        if (!users) {
          return [];
        }

        return users.map((obj: any) => ({...obj, icon: obj.is_partner ? 'mdi-account-multiple' : 'mdi-account'}));
      }

      get assigneeDropdownLabel() {
        if(companyModule.company?.modules?.includes('DFB_PORTAL_PARTNERS')) {
          return 'userPartner';
        }
        else {
          return 'user';
        }
      }
    
      updateUsers(data: string[]) {
        this.selectedUsers = data;
        this.submit();
      }

      updatActionTypes(data: string[]) {
        this.selectedActionTypes = data;
        this.submit();
      }

      updateDates(data: any[]) {
        this.selectedDates = data;
        if(!this.selectedDates || this.selectedDates.length == 2) {
          this.submit();
        }
      }
    
      updateActionTypes(data: string[]) {
        this.selectedActionTypes = data;
        this.submit();
      }

      updateStatus(data: any[]) {
        this.selectedStatuses = data;
        this.submit();
      }

      updateDeadLine(data: Record<string, any>) {
        this.selectedDeadLineOption = data.map((option: Record<string, any>) => option.value.toString());
        this.submit();
      }

      updateCategoryTypes(data: string[]) {
        this.selectedCategoryTypes = data;
        this.submit();
      }
      
      async submit() {
        let queryObj: any = {};
        tasksModule.setIsLoadingTable(true);
        let userIds = [];
        let tempId;
        let actionsIds: any = [];
        let actionsTempId;
        let statusTempId;
        let statusIds = [];
    
        if (this.selectedUsers != undefined) {
          for (let i = 0; i < this.selectedUsers.length; i++) {
            tempId = (this.selectedUsers[i] as any).id
            userIds.push(tempId);
          }
        }
    
        if (userIds != undefined && userIds.length > 0) {
          queryObj["assignee_id[]"] = userIds;
        }

      if (this.selectedActionTypes != undefined) {
        for (let i = 0; i < this.selectedActionTypes.length; i++) {
          actionsTempId = (this.selectedActionTypes[i] as any).id
          actionsIds.push(actionsTempId);
        }
      }

      if (actionsIds != undefined && actionsIds.length > 0) {
        queryObj["actions[]"] = actionsIds;
      }

      if (this.selectedStatuses != undefined) {
        for (let i = 0; i < this.selectedStatuses.length; i++) {
          statusTempId = (this.selectedStatuses[i] as any).id
          statusIds.push(statusTempId);
        }
      }

      if (statusIds != undefined && statusIds.length > 0) {
        queryObj["taskStatus[]"] = statusIds;
      }

      if (this.selectedDates != null && this.selectedDates.length === 2) {
        sortDateRange(this.selectedDates);

        queryObj["date_from"] = dayjs(this.selectedDates[0]).startOf('day').toISOString();
        queryObj["date_to"] = dayjs(this.selectedDates[1]).endOf('day').toISOString();
      }

      if (tasksModule.search != '') {
        tasksModule.setSearch(tasksModule.search);
        queryObj["search"] = tasksModule.search;
      }

      if (this.selectedDeadLineOption != undefined && this.selectedDeadLineOption.length > 0) {
        queryObj["deadLine[]"] = this.selectedDeadLineOption;
      }

      let categoriesTempId;
      let categoriesIds = [];

      if (this.selectedCategoryTypes != undefined) {
        for (let i = 0; i < this.selectedCategoryTypes.length; i++) {
          categoriesTempId = (this.selectedCategoryTypes[i] as any).id
          categoriesIds.push(categoriesTempId);
        }
      }

      if (categoriesIds != undefined && categoriesIds.length > 0) {
        queryObj["categories[]"] = categoriesIds;
      }
    
        this.$router.replace({ query: queryObj }).catch(err => {/* */ });
    
        // Check if user selected any of the queries
        const queryIndex = this.$route.fullPath.indexOf('?');
        if (queryIndex >= 0) {
          tasksModule.setQueryObject(queryObj);
          const query = this.$route.fullPath.substring(queryIndex);
          tasksModule.setFilters(query);
          tasksModule.setQueryObject(queryObj);
        } else {
          tasksModule.setFilters('');
          tasksModule.setQueryObject(queryObj); // Will be empty ({})
        }
        await tasksModule.getTasks(tasksModule.filters)
        tasksModule.setIsLoadingTable(false);
        }
    }
    